exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-airbnb-collection-js": () => import("./../../../src/pages/airbnb-collection.js" /* webpackChunkName: "component---src-pages-airbnb-collection-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-commission-request-js": () => import("./../../../src/pages/commission-request.js" /* webpackChunkName: "component---src-pages-commission-request-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-originals-js": () => import("./../../../src/pages/originals.js" /* webpackChunkName: "component---src-pages-originals-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-prints-js": () => import("./../../../src/pages/prints.js" /* webpackChunkName: "component---src-pages-prints-js" */),
  "component---src-pages-shipping-and-returns-js": () => import("./../../../src/pages/shipping-and-returns.js" /* webpackChunkName: "component---src-pages-shipping-and-returns-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/Product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

